import "./i18n";
import { COIN_PACKAGE_LIST as BASE_COIN_PACKAGE_LIST, COIN_PACKAGE_CREATE as BASE_COIN_PACKAGE_CREATE, COIN_PACKAGE_EDIT as BASE_COIN_PACKAGE_EDIT, COIN_PACKAGE_VIEW as BASE_COIN_PACKAGE_VIEW, } from "./config/routes";
export const COIN_PACKAGE_LIST = {
    ...BASE_COIN_PACKAGE_LIST,
};
export const COIN_PACKAGE_CREATE = {
    ...BASE_COIN_PACKAGE_CREATE,
};
export const COIN_PACKAGE_EDIT = {
    ...BASE_COIN_PACKAGE_EDIT,
};
export const COIN_PACKAGE_VIEW = {
    ...BASE_COIN_PACKAGE_VIEW,
};
