import { LeadCreditPackagesList } from "../components/List/LeadCreditPackagesList";
import { LeadCreditPackagesCreate } from "../components/Create/LeadCreditPackagesCreate";
import { LeadCreditPackagesEdit } from "../components/Edit/LeadCreditPackagesEdit";
import { LeadCreditPackagesView } from "../components/View/LeadCreditPackagesView";
import { SettingFilled } from "@ant-design/icons";
export const LEAD_CREDIT_PACKAGES_LIST = {
    path: "/admin/lead-credit-packages",
    component: LeadCreditPackagesList,
    menu: {
        key: "LEAD_CREDIT_PACKAGES_LIST",
        label: "management.lead_credit_packages.menu.title",
        icon: SettingFilled,
    },
};
export const LEAD_CREDIT_PACKAGES_CREATE = {
    path: "/admin/lead-credit-packages/create",
    component: LeadCreditPackagesCreate,
};
export const LEAD_CREDIT_PACKAGES_EDIT = {
    path: "/admin/lead-credit-packages/:id/edit",
    component: LeadCreditPackagesEdit,
};
export const LEAD_CREDIT_PACKAGES_VIEW = {
    path: "/admin/lead-credit-packages/:id/view",
    component: LeadCreditPackagesView,
};
