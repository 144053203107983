var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { createElement as _createElement } from "react";
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Service } from "@bluelibs/core";
import { XViewer } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
let BusinessViewer = class BusinessViewer extends XViewer {
    build() {
        const { UIComponents, router } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "_id",
                label: t("management.businesses.fields._id"),
                dataIndex: ["_id"],
                render: (value) => {
                    const props = {
                        type: "objectId",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "name",
                label: t("management.businesses.fields.name"),
                dataIndex: ["name"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "address",
                label: t("management.businesses.fields.address"),
                dataIndex: ["address"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "latitude",
                label: t("management.businesses.fields.latitude"),
                dataIndex: ["latitude"],
                render: (value) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "longitude",
                label: t("management.businesses.fields.longitude"),
                dataIndex: ["longitude"],
                render: (value) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "description",
                label: t("management.businesses.fields.description"),
                dataIndex: ["description"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "phoneNumber",
                label: t("management.businesses.fields.phoneNumber"),
                dataIndex: ["phoneNumber"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "socialMediaLinks.facebook",
                label: t("management.businesses.fields.socialMediaLinks.facebook"),
                dataIndex: ["socialMediaLinks", "facebook"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "socialMediaLinks.twitter",
                label: t("management.businesses.fields.socialMediaLinks.twitter"),
                dataIndex: ["socialMediaLinks", "twitter"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "socialMediaLinks.linkedin",
                label: t("management.businesses.fields.socialMediaLinks.linkedin"),
                dataIndex: ["socialMediaLinks", "linkedin"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "socialMediaLinks.instagram",
                label: t("management.businesses.fields.socialMediaLinks.instagram"),
                dataIndex: ["socialMediaLinks", "instagram"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "accountPaused",
                label: t("management.businesses.fields.accountPaused"),
                dataIndex: ["accountPaused"],
                render: (value) => {
                    const props = {
                        type: "boolean",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "isHold",
                label: t("management.businesses.fields.isHold"),
                dataIndex: ["isHold"],
                render: (value) => {
                    const props = {
                        type: "boolean",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "isViewed",
                label: t("management.businesses.fields.isViewed"),
                dataIndex: ["isViewed"],
                render: (value) => {
                    const props = {
                        type: "boolean",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "numberOfCredits",
                label: t("management.businesses.fields.numberOfCredits"),
                dataIndex: ["numberOfCredits"],
                render: (value) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "leadCredits",
                label: t("management.businesses.fields.leadCredits"),
                dataIndex: ["leadCredits"],
                render: (value) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "coin",
                label: t("management.businesses.fields.coin"),
                dataIndex: ["coin"],
                render: (value) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "accountType",
                label: t("management.businesses.fields.accountType"),
                dataIndex: ["accountType"],
                render: (value) => {
                    return (_jsx(_Fragment, { children: value &&
                            value.map((value, idx) => {
                                const props = {
                                    type: "enum",
                                    value,
                                    labelify: true,
                                };
                                return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                            }) }, void 0));
                },
            },
            {
                id: "theme",
                label: t("management.businesses.fields.theme"),
                dataIndex: ["theme"],
                render: (value) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "requestedCourses",
                label: t("management.businesses.fields.requestedCourses"),
                dataIndex: ["requestedCourses"],
                render: (value) => {
                    return (_jsx(_Fragment, { children: value &&
                            value.map((value, idx) => {
                                const props = {
                                    type: "object",
                                    value,
                                };
                                return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                            }) }, void 0));
                },
            },
            {
                id: "stripeCustomerId",
                label: t("management.businesses.fields.stripeCustomerId"),
                dataIndex: ["stripeCustomerId"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "isOnboarded",
                label: t("management.businesses.fields.isOnboarded"),
                dataIndex: ["isOnboarded"],
                render: (value) => {
                    const props = {
                        type: "boolean",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "createdAt",
                label: t("management.businesses.fields.createdAt"),
                dataIndex: ["createdAt"],
                render: (value) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "updatedAt",
                label: t("management.businesses.fields.updatedAt"),
                dataIndex: ["updatedAt"],
                render: (value) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "createdById",
                label: t("management.businesses.fields.createdById"),
                dataIndex: ["createdById"],
                render: (value) => {
                    const props = {
                        type: "objectId",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "updatedById",
                label: t("management.businesses.fields.updatedById"),
                dataIndex: ["updatedById"],
                render: (value) => {
                    const props = {
                        type: "objectId",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "owner",
                label: t("management.businesses.fields.owner"),
                dataIndex: ["owner"],
                render: (value) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.USERS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "fullName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "logo",
                label: t("management.businesses.fields.logo"),
                dataIndex: ["logo"],
                render: (value) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.IMAGES_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "_id",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "country",
                label: t("management.businesses.fields.country"),
                dataIndex: ["country"],
                render: (value) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.COUNTRIES_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "_id",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "timezone",
                label: t("management.businesses.fields.timezone"),
                dataIndex: ["timezone"],
                render: (value) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.TIMEZONES_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "_id",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "subscription",
                label: t("management.businesses.fields.subscription"),
                dataIndex: ["subscription"],
                render: (value) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.BUSINESS_SUBSCRIPTIONS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "stripeSubscriptionId",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "addon",
                label: t("management.businesses.fields.addon"),
                dataIndex: ["addon"],
                render: (value) => {
                    return (_jsx(_Fragment, { children: value &&
                            value.map((value, idx) => {
                                const props = {
                                    type: "relation",
                                    value,
                                    relation: {
                                        path: router.path(Routes.BUSINESS_ADDONS_VIEW, {
                                            params: {
                                                id: value === null || value === void 0 ? void 0 : value._id,
                                            },
                                        }),
                                        dataIndex: "stripeAddonSubscriptionId",
                                    },
                                };
                                return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                            }) }, void 0));
                },
            },
            {
                id: "calendar",
                label: t("management.businesses.fields.calendar"),
                dataIndex: ["calendar"],
                render: (value) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.CALENDARS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "_id",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "todosList",
                label: t("management.businesses.fields.todosList"),
                dataIndex: ["todosList"],
                render: (value) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.TODOS_LISTS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "_id",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
        ]);
    }
    static getRequestBody() {
        return {
            _id: 1,
            name: 1,
            address: 1,
            latitude: 1,
            longitude: 1,
            description: 1,
            phoneNumber: 1,
            socialMediaLinks: {
                facebook: 1,
                twitter: 1,
                linkedin: 1,
                instagram: 1,
            },
            accountPaused: 1,
            isHold: 1,
            isViewed: 1,
            numberOfCredits: 1,
            leadCredits: 1,
            coin: 1,
            accountType: 1,
            theme: 1,
            requestedCourses: {
                courseId: 1,
                endDate: 1,
            },
            stripeCustomerId: 1,
            isOnboarded: 1,
            createdAt: 1,
            updatedAt: 1,
            createdById: 1,
            updatedById: 1,
            owner: {
                _id: 1,
                fullName: 1,
            },
            ownerId: 1,
            logo: {
                _id: 1,
            },
            logoId: 1,
            country: {
                _id: 1,
            },
            countryId: 1,
            timezone: {
                _id: 1,
            },
            timezoneId: 1,
            subscription: {
                _id: 1,
                stripeSubscriptionId: 1,
            },
            subscriptionId: 1,
            addon: {
                _id: 1,
                stripeAddonSubscriptionId: 1,
            },
            addonIds: 1,
            calendar: {
                _id: 1,
            },
            calendarId: 1,
            todosList: {
                _id: 1,
            },
            todosListId: 1,
        };
    }
};
BusinessViewer = __decorate([
    Service({ transient: true })
], BusinessViewer);
export { BusinessViewer };
