import { Collection, } from "@bluelibs/x-ui";
import { CoinPackage } from "@root/api.types";
export class CoinPackageCollection extends Collection {
    getName() {
        return "CoinPackage";
    }
    getInputs() {
        return {
            insert: "CoinPackageInsertInput!",
            update: "CoinPackageUpdateInput!",
        };
    }
    // Return here the relations with other configs
    getLinks() {
        return [];
    }
    // Return here how you want to transform certain fields
    getTransformMap() {
        return {
            createdAt: (v) => (v && new Date(v) ? new Date(v) : v),
            updatedAt: (v) => (v && new Date(v) ? new Date(v) : v),
        };
    }
}
