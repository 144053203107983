var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { XList } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
import { Service } from "@bluelibs/core";
let NotificationList = class NotificationList extends XList {
    build() {
        const { UIComponents, router } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "type",
                title: t("management.notifications.fields.type"),
                key: "management.notifications.fields.type",
                dataIndex: ["type"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "actionType",
                title: t("management.notifications.fields.actionType"),
                key: "management.notifications.fields.actionType",
                dataIndex: ["actionType"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "seen",
                title: t("management.notifications.fields.seen"),
                key: "management.notifications.fields.seen",
                dataIndex: ["seen"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "boolean",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "createdAt",
                title: t("management.notifications.fields.createdAt"),
                key: "management.notifications.fields.createdAt",
                dataIndex: ["createdAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "updatedAt",
                title: t("management.notifications.fields.updatedAt"),
                key: "management.notifications.fields.updatedAt",
                dataIndex: ["updatedAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "createdById",
                title: t("management.notifications.fields.createdById"),
                key: "management.notifications.fields.createdById",
                dataIndex: ["createdById"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "objectId",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "updatedById",
                title: t("management.notifications.fields.updatedById"),
                key: "management.notifications.fields.updatedById",
                dataIndex: ["updatedById"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "objectId",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "sender",
                title: t("management.notifications.fields.sender"),
                key: "management.notifications.fields.sender",
                dataIndex: ["sender"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.USERS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "fullName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "user",
                title: t("management.notifications.fields.user"),
                key: "management.notifications.fields.user",
                dataIndex: ["user"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.USERS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "fullName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "post",
                title: t("management.notifications.fields.post"),
                key: "management.notifications.fields.post",
                dataIndex: ["post"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.CHANNEL_MESSAGES_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "_id",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "message",
                title: t("management.notifications.fields.message"),
                key: "management.notifications.fields.message",
                dataIndex: ["message"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.CHAT_MESSAGE_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "_id",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
        ]);
    }
    static getSortMap() {
        return {
            sender: "sender.fullName",
            user: "user.fullName",
            post: "post._id",
            message: "message._id",
        };
    }
    static getRequestBody() {
        return {
            _id: 1,
            type: 1,
            actionType: 1,
            seen: 1,
            createdAt: 1,
            updatedAt: 1,
            createdById: 1,
            updatedById: 1,
            sender: {
                _id: 1,
                fullName: 1,
            },
            senderId: 1,
            user: {
                _id: 1,
                fullName: 1,
            },
            userId: 1,
            post: {
                _id: 1,
            },
            postId: 1,
            message: {
                _id: 1,
            },
            messageId: 1,
        };
    }
};
NotificationList = __decorate([
    Service({ transient: true })
], NotificationList);
export { NotificationList };
