var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service, Inject } from "@bluelibs/core";
import { SmileOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { CoursesSectionsCollection, ImagesCollection, CoursesCollection, } from "@bundles/UIAppBundle/collections";
let CourseEditForm = class CourseEditForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "title",
                label: t("management.courses.fields.title"),
                name: ["title"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "description",
                label: t("management.courses.fields.description"),
                name: ["description"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "estimatedReadingTimeInHours",
                label: t("management.courses.fields.estimatedReadingTimeInHours"),
                name: ["estimatedReadingTimeInHours"],
                required: true,
                component: Ant.InputNumber,
            },
            {
                id: "type",
                label: t("management.courses.fields.type"),
                name: ["type"],
                required: true,
                render: (props) => {
                    const options = [
                        { value: "INTRO", label: "Intro" },
                        { value: "ELITE", label: "Elite" },
                        { value: "PREMIUM", label: "Premium" },
                    ];
                    return (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Select, Object.assign({ placeholder: t("management.courses.fields.type") }, { children: options.map((option) => {
                                var _a;
                                return (_jsx(Ant.Select.Option, Object.assign({ value: option.value }, { children: (_a = t(`management.courses.fields.type_enums.${option.label.toLowerCase()}`)) !== null && _a !== void 0 ? _a : option.label }), option.value));
                            }) }), void 0) }), void 0));
                },
            },
            {
                id: "accountType",
                label: t("management.courses.fields.accountType"),
                name: ["accountType"],
                required: true,
                render: (props) => {
                    const options = [
                        { value: "DROPSERVICEAGENCY", label: "Dropserviceagency" },
                        { value: "DROPSERVICECREATOR", label: "Dropservicecreator" },
                    ];
                    return (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Select, Object.assign({ placeholder: t("management.courses.fields.accountType") }, { children: options.map((option) => {
                                var _a;
                                return (_jsx(Ant.Select.Option, Object.assign({ value: option.value }, { children: (_a = t(`management.courses.fields.accountType_enums.${option.label.toLowerCase()}`)) !== null && _a !== void 0 ? _a : option.label }), option.value));
                            }) }), void 0) }), void 0));
                },
            },
            {
                id: "sectionsIds",
                label: t("management.courses.fields.sections"),
                name: ["sectionsIds"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: CoursesSectionsCollection, field: "_id", required: true, mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "coverId",
                label: t("management.courses.fields.cover"),
                name: ["coverId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: ImagesCollection, field: "_id", required: false }, void 0) }), void 0)),
            },
        ]);
    }
    static getRequestBody() {
        return {
            _id: 1,
            title: 1,
            description: 1,
            estimatedReadingTimeInHours: 1,
            type: 1,
            accountType: 1,
            sections: {
                _id: 1,
            },
            sectionsIds: 1,
            cover: {
                _id: 1,
            },
            coverId: 1,
        };
    }
    onSubmit(_id, values) {
        const { t } = this.i18n;
        return this.collection
            .updateOne(_id, { $set: values })
            .then(({ _id }) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.courses.edit_confirmation"),
                icon: _jsx(SmileOutlined, {}, void 0),
            });
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
__decorate([
    Inject(() => CoursesCollection),
    __metadata("design:type", typeof (_a = typeof CoursesCollection !== "undefined" && CoursesCollection) === "function" ? _a : Object)
], CourseEditForm.prototype, "collection", void 0);
CourseEditForm = __decorate([
    Service({ transient: true })
], CourseEditForm);
export { CourseEditForm };
