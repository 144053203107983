var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service } from "@bluelibs/core";
import * as Ant from "antd";
import { UsersCollection, ChannelMessagesCollection, ChatMessageCollection, } from "@bundles/UIAppBundle/collections";
let NotificationListFiltersForm = class NotificationListFiltersForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "type",
                label: t("management.notifications.fields.type"),
                name: ["type"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Select, Object.assign({ mode: "multiple", placeholder: t("management.notifications.fields.type") }, { children: [_jsx(Ant.Select.Option, Object.assign({ value: "POST" }, { children: "Post" }), "POST"), _jsx(Ant.Select.Option, Object.assign({ value: "INBOX" }, { children: "Inbox" }), "INBOX")] }), void 0) }), void 0)),
            },
            {
                id: "actionType",
                label: t("management.notifications.fields.actionType"),
                name: ["actionType"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Select, Object.assign({ mode: "multiple", placeholder: t("management.notifications.fields.actionType") }, { children: [_jsx(Ant.Select.Option, Object.assign({ value: "LIKE" }, { children: "Like" }), "LIKE"), _jsx(Ant.Select.Option, Object.assign({ value: "COMMENT" }, { children: "Comment" }), "COMMENT"), _jsx(Ant.Select.Option, Object.assign({ value: "MESSAGE" }, { children: "Message" }), "MESSAGE")] }), void 0) }), void 0)),
            },
            {
                id: "seen",
                label: t("management.notifications.fields.seen"),
                name: ["seen"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, Object.assign({ value: false }, { children: "No" }), 0), _jsx(Ant.Radio, Object.assign({ value: true }, { children: "Yes" }), 1)] }, void 0) }), void 0)),
            },
            {
                id: "createdAt",
                label: t("management.notifications.fields.createdAt"),
                name: ["createdAt"],
                tooltip: t("management.notifications.fields.createdAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
            {
                id: "updatedAt",
                label: t("management.notifications.fields.updatedAt"),
                name: ["updatedAt"],
                tooltip: t("management.notifications.fields.updatedAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
            {
                id: "senderId",
                label: t("management.notifications.fields.sender"),
                name: ["senderId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: UsersCollection, field: "fullName", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "userId",
                label: t("management.notifications.fields.user"),
                name: ["userId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: UsersCollection, field: "fullName", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "postId",
                label: t("management.notifications.fields.post"),
                name: ["postId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: ChannelMessagesCollection, field: "_id", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "messageId",
                label: t("management.notifications.fields.message"),
                name: ["messageId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: ChatMessageCollection, field: "_id", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
        ]);
    }
};
NotificationListFiltersForm = __decorate([
    Service({ transient: true })
], NotificationListFiltersForm);
export { NotificationListFiltersForm };
