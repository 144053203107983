import "./i18n";
import { CHAT_MESSAGE_LIST as BASE_CHAT_MESSAGE_LIST, CHAT_MESSAGE_CREATE as BASE_CHAT_MESSAGE_CREATE, CHAT_MESSAGE_EDIT as BASE_CHAT_MESSAGE_EDIT, CHAT_MESSAGE_VIEW as BASE_CHAT_MESSAGE_VIEW, } from "./config/routes";
export const CHAT_MESSAGE_LIST = {
    ...BASE_CHAT_MESSAGE_LIST,
};
export const CHAT_MESSAGE_CREATE = {
    ...BASE_CHAT_MESSAGE_CREATE,
};
export const CHAT_MESSAGE_EDIT = {
    ...BASE_CHAT_MESSAGE_EDIT,
};
export const CHAT_MESSAGE_VIEW = {
    ...BASE_CHAT_MESSAGE_VIEW,
};
