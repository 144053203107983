import { Collection, } from "@bluelibs/x-ui";
import { ChatMessage } from "@root/api.types";
import { UsersCollection } from "@bundles/UIAppBundle/collections";
export class ChatMessageCollection extends Collection {
    getName() {
        return "ChatMessage";
    }
    getInputs() {
        return {
            insert: "ChatMessageInsertInput!",
            update: "ChatMessageUpdateInput!",
        };
    }
    // Return here the relations with other configs
    getLinks() {
        return [
            {
                collection: () => UsersCollection,
                name: "sender",
                field: "senderId",
            },
            {
                collection: () => UsersCollection,
                name: "receiver",
                field: "receiverId",
            },
        ];
    }
    // Return here how you want to transform certain fields
    getTransformMap() {
        return {};
    }
}
