import "./i18n";
import { LEAD_CREDIT_PACKAGES_LIST as BASE_LEAD_CREDIT_PACKAGES_LIST, LEAD_CREDIT_PACKAGES_CREATE as BASE_LEAD_CREDIT_PACKAGES_CREATE, LEAD_CREDIT_PACKAGES_EDIT as BASE_LEAD_CREDIT_PACKAGES_EDIT, LEAD_CREDIT_PACKAGES_VIEW as BASE_LEAD_CREDIT_PACKAGES_VIEW, } from "./config/routes";
export const LEAD_CREDIT_PACKAGES_LIST = {
    ...BASE_LEAD_CREDIT_PACKAGES_LIST,
};
export const LEAD_CREDIT_PACKAGES_CREATE = {
    ...BASE_LEAD_CREDIT_PACKAGES_CREATE,
};
export const LEAD_CREDIT_PACKAGES_EDIT = {
    ...BASE_LEAD_CREDIT_PACKAGES_EDIT,
};
export const LEAD_CREDIT_PACKAGES_VIEW = {
    ...BASE_LEAD_CREDIT_PACKAGES_VIEW,
};
