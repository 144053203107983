import { ChatMessageList } from "../components/List/ChatMessageList";
import { ChatMessageCreate } from "../components/Create/ChatMessageCreate";
import { ChatMessageEdit } from "../components/Edit/ChatMessageEdit";
import { ChatMessageView } from "../components/View/ChatMessageView";
import { SettingFilled } from "@ant-design/icons";
export const CHAT_MESSAGE_LIST = {
    path: "/admin/chat-message",
    component: ChatMessageList,
    menu: {
        key: "CHAT_MESSAGE_LIST",
        label: "management.chat_message.menu.title",
        icon: SettingFilled,
    },
};
export const CHAT_MESSAGE_CREATE = {
    path: "/admin/chat-message/create",
    component: ChatMessageCreate,
};
export const CHAT_MESSAGE_EDIT = {
    path: "/admin/chat-message/:id/edit",
    component: ChatMessageEdit,
};
export const CHAT_MESSAGE_VIEW = {
    path: "/admin/chat-message/:id/view",
    component: ChatMessageView,
};
