import { CoinPackageList } from "../components/List/CoinPackageList";
import { CoinPackageCreate } from "../components/Create/CoinPackageCreate";
import { CoinPackageEdit } from "../components/Edit/CoinPackageEdit";
import { CoinPackageView } from "../components/View/CoinPackageView";
import { SettingFilled } from "@ant-design/icons";
export const COIN_PACKAGE_LIST = {
    path: "/admin/coin-package",
    component: CoinPackageList,
    menu: {
        key: "COIN_PACKAGE_LIST",
        label: "management.coin_package.menu.title",
        icon: SettingFilled,
    },
};
export const COIN_PACKAGE_CREATE = {
    path: "/admin/coin-package/create",
    component: CoinPackageCreate,
};
export const COIN_PACKAGE_EDIT = {
    path: "/admin/coin-package/:id/edit",
    component: CoinPackageEdit,
};
export const COIN_PACKAGE_VIEW = {
    path: "/admin/coin-package/:id/view",
    component: CoinPackageView,
};
