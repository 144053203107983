import { NotificationsList } from "../components/List/NotificationsList";
import { SettingFilled } from "@ant-design/icons";
export const NOTIFICATIONS_LIST = {
    path: "/admin/notifications",
    component: NotificationsList,
    menu: {
        key: "NOTIFICATIONS_LIST",
        label: "management.notifications.menu.title",
        icon: SettingFilled,
    },
};
export const NOTIFICATIONS_CREATE = {
    path: "/admin/notifications/create",
    component: () => {
        console.error("This route is not available.");
        return null;
    },
};
export const NOTIFICATIONS_EDIT = {
    path: "/admin/notifications/:id/edit",
    component: () => {
        console.error("This route is not available.");
        return null;
    },
};
export const NOTIFICATIONS_VIEW = {
    path: "/admin/notifications/:id/view",
    component: () => {
        console.error("This route is not available.");
        return null;
    },
};
